export interface BusinessDetailState {
  business: any;
  success: boolean;
  loading: boolean;
  error: unknown;
}

export const initialBusinessDetail: BusinessDetailState = {
  business: null,
  success: false,
  loading: false,
  error: null,
};
