import { Injectable } from "@angular/core";
import { BusinessesApiService } from "../../services/businesses-api.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BusinessDetailActions } from "../actions";
import { catchError, map, of, switchMap, throwError } from "rxjs";

@Injectable()
export class BusinessDetailEffect {
  constructor(private action$: Actions, private businessService: BusinessesApiService) {}

  getBusinessDetail$ = createEffect(() =>
    this.action$.pipe(
      ofType(BusinessDetailActions.getBusinessDetail),
      switchMap((params) =>
        this.businessService.getBusinessDetail(params.business?.id || "").pipe(
          map((res) => BusinessDetailActions.getBusinessDetailSuccess(res)),
          catchError(() => of(BusinessDetailActions.getBusinessDetailFailed({ error: true })))
        )
      )
    )
  );
}
