import { inject, Pipe, PipeTransform } from "@angular/core";
import { CustomTranslateService, LOCALE_LANG_ID, LocaleService } from "../locale";

@Pipe({
  name: "customTranslate",
  standalone: true,
})
export class CustomTranslatePipe implements PipeTransform {
  private readonly _localeService = inject(LocaleService);
  private readonly _customTranslate = inject(CustomTranslateService);

  transform(key: string, langId?: LOCALE_LANG_ID | string) {
    let currentLangId = langId || this._localeService?.currentLocale?.langId || "en";

    return this._customTranslate.getTranslations(key, currentLangId);
  }
}
