import { provideAuth0 } from "@auth0/auth0-angular";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { provideEffects } from "@ngrx/effects";
import { provideState, provideStore } from "@ngrx/store";
import { environment } from "../environments/environment";
import appRoutes from "./routes";
import { AuthEffects, authSlice } from "./auth/state";
import { loadingSlice } from "./store/loading/loading.slice";
import { RefreshTokenEffects, refreshTokenSlice } from "./store";
import { AddBusinessEffects, addBusinessSlice } from "./add-business/states";
import { addBusinessMenuSlice, BusinessMenuEffects } from "./business-menu/states";
import {
  addDishPriceSlice,
  addMenuDishSlice,
  DishListEffects,
  dishListSlice,
  DishMenuEffects,
  DishPricesEffects,
} from "./dish/states";
import { menuSlice } from "./business-menu/states/business-menu-list.slice";
import { businessDetailSlice } from "./my-business/states";
import { menuTabSlice } from "./dish/menu-tabs/states/menu-tabs.slice";
import { LOCALE, LocaleHeaderInterceptor, provideLocale } from "@eqn/angular-i18n";
import { BusinessDetailEffect } from "./my-business/states/effects";

export const appConfig: ApplicationConfig = {
  providers: [
    MatBottomSheet,
    provideAnimations(),
    provideHttpClient(withInterceptors([LocaleHeaderInterceptor])),
    provideRouter(appRoutes),
    provideStore(),
    provideState(loadingSlice),
    provideState(menuTabSlice),
    provideState(authSlice),
    provideState(refreshTokenSlice),
    provideState(addBusinessSlice),
    provideState(addBusinessMenuSlice),
    provideState(menuSlice),
    provideState(addMenuDishSlice),
    provideState(dishListSlice),
    provideState(addDishPriceSlice),
    provideState(businessDetailSlice),
    provideEffects(
      AuthEffects,
      RefreshTokenEffects,
      AddBusinessEffects,
      BusinessMenuEffects,
      DishMenuEffects,
      DishListEffects,
      DishPricesEffects,
      BusinessDetailEffect
    ),
    provideAuth0(environment.auth),
    provideLocale({
      currencyLocale: LOCALE.es.langId,
      cacheLocale: true,
      reloadOnChange: true,
      availableLanguages: [LOCALE.es],
      automaticTranslations: Object.values(LOCALE).filter(
        (locale) => locale.langId !== LOCALE.es.langId
      ),
    }),
    environment?.providers || [],
  ],
};
