import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { UserAuthService } from "../../auth/services/user-auth.service";
import { map, Observable } from "rxjs";
import { Dish, PaginationResult, Price } from "@menufy/models";
import { environment } from "../../../environments/environment";
import { DISHES_DEFAULT_PAGE_LIMIT, DishListParams } from "../interfaces";
import { CustomTranslateService } from "@eqn/angular-i18n";

@Injectable({
  providedIn: "root",
})
export class DishesApiService {
  private readonly _http = inject(HttpClient);
  private readonly _userAuth = inject(UserAuthService);
  private readonly _customTranslate = inject(CustomTranslateService);
  private readonly _dishesApiBaseUrl = `${environment.apiBaseUrl}/dishes`;
  private readonly _dishesApiI18nBaseUrl = `${environment.apiBaseUrl}/i18n/dishes`;

  public addMenuDish(body: FormData): Observable<Partial<Dish>> {
    return this._http.post<Partial<Dish>>(
      this._dishesApiBaseUrl,
      body,
      this._userAuth.getAuthorizationHeader()
    );
  }

  public updateMenuDish(
    body: FormData | any,
    dishId: string,
    i18n: boolean = false
  ): Observable<Partial<Dish>> {
    if (i18n) {
      const url = `${this._dishesApiI18nBaseUrl}/${dishId}`;
      return this._http.patch<Partial<Dish>>(
        url,
        body,
        this._userAuth.getAuthorizationHeader("", true)
      );
    } else {
      const url = `${this._dishesApiBaseUrl}/${dishId}`;
      return this._http.put<Partial<Dish>>(
        url,
        body,
        this._userAuth.getAuthorizationHeader("", true)
      );
    }
  }

  public deleteMenuDish(dishId: string): Observable<{ id: string }> {
    const url = `${this._dishesApiBaseUrl}/${dishId}`;
    return this._http.delete<{ id: string }>(url, this._userAuth.getAuthorizationHeader());
  }

  public getDishesByBusiness(params: DishListParams): Observable<PaginationResult<Dish>> {
    const urlParams = new URLSearchParams();

    const limit = `${params.limit || DISHES_DEFAULT_PAGE_LIMIT}`;

    urlParams.append("limit", limit);

    if (params.before) {
      urlParams.append("before", params.before);
    } else if (params.after) {
      urlParams.append("after", params.after);
    }

    if (params.business) {
      urlParams.append("query[business]", params.business);
    }

    const url = `${this._dishesApiBaseUrl}?${urlParams.toString()}`;

    return this._http
      .get<PaginationResult<Dish>>(url, this._userAuth.getAuthorizationHeader("", true))
      ?.pipe(
        map((res) => ({
          ...res,
          data: res?.data?.length
            ? res?.data?.sort((a, b) => (a?.position || 1) - (b?.position || 1))
            : [],
        }))
      );
  }

  public addDishPrice(body: Price, dishId: string): Observable<Price> {
    const url = `${this._dishesApiBaseUrl}/${dishId}/prices`;
    const payload = {
      amount: body.amount || "",
    };
    if (body.description) {
      Object.assign(payload, { description: body.description || "" });
    }
    return this._http.post<Price>(url, payload, this._userAuth.getAuthorizationHeader());
  }

  public updateDishPrice(body: Price, dishId: string): Observable<Price> {
    const url = `${this._dishesApiBaseUrl}/${dishId}/prices/${body.id}`;
    const payload = {
      amount: body.amount || "",
    };

    if (body.description) {
      Object.assign(payload, { description: body.description || "" });
    }
    return this._http.put<Price>(url, payload, this._userAuth.getAuthorizationHeader());
  }

  public deleteDishPrice(priceId: string, dishId: string): Observable<{ id: string }> {
    const url = `${this._dishesApiBaseUrl}/${dishId}/prices/${priceId}`;
    return this._http.delete<{ id: string }>(url, this._userAuth.getAuthorizationHeader());
  }

  public deleteDishImage(dishId: string, imageId: string) {
    const url = `${this._dishesApiBaseUrl}/${dishId}/images/${imageId}`;
    return this._http.delete(url, this._userAuth.getAuthorizationHeader());
  }
}
