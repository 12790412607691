import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { UserAuthService } from "../../auth/services/user-auth.service";
import { map, Observable } from "rxjs";
import { Menu, PaginationResult } from "@menufy/models";
import {
  MenuListParams,
  MENUS_DEFAULT_PAGE_LIMIT,
} from "../interfaces/list-business-params.interface";
import { CustomTranslateService } from "@eqn/angular-i18n";

@Injectable({
  providedIn: "root",
})
export class BusinessMenuService {
  private readonly _http = inject(HttpClient);
  private readonly _userAuth = inject(UserAuthService);
  private readonly _translate = inject(CustomTranslateService);
  private readonly _menusApiBaseUrl = `${environment.apiBaseUrl}/menus`;
  private readonly _menusApiBasei18nUrl = `${environment.apiBaseUrl}/i18n/menus`;

  public addBusinessMenu(body: Partial<Menu>): Observable<Menu> {
    return this._http.post<Menu>(
      this._menusApiBaseUrl,
      body,
      this._userAuth.getAuthorizationHeader("", true)
    );
  }

  public updateBusinessMenu(
    body: Partial<Menu>,
    menuId: string,
    i18n: boolean = false
  ): Observable<Menu> {
    if (i18n) {
      const url = `${this._menusApiBasei18nUrl}/${menuId}`;
      return this._http.patch<Menu>(url, body, this._userAuth.getAuthorizationHeader("", true));
    } else {
      const url = `${this._menusApiBaseUrl}/${menuId}`;
      return this._http.put<Menu>(url, body, this._userAuth.getAuthorizationHeader("", true));
    }
  }

  public deleteMenu(menuId: string): Observable<{ id: string }> {
    const url = `${this._menusApiBaseUrl}/${menuId}`;
    return this._http.delete<{ id: string }>(url, this._userAuth.getAuthorizationHeader());
  }

  public getMenusByBusiness(params: MenuListParams): Observable<PaginationResult<Menu>> {
    const urlParams = new URLSearchParams();

    const limit = `${params.limit || MENUS_DEFAULT_PAGE_LIMIT}`;

    urlParams.append("limit", limit);

    if (params.business) {
      urlParams.append("query[business]", params.business);
    }

    if (params.before) {
      urlParams.append("before", params.before);
    } else if (params.after) {
      urlParams.append("after", params.after);
    }

    const url = `${this._menusApiBaseUrl}?${urlParams.toString()}`;

    return this._http
      .get<PaginationResult<Menu>>(url, this._userAuth.getAuthorizationHeader("", true))
      .pipe(
        map((res) => ({
          ...res,
          data: res?.data?.length
            ? res?.data?.sort((a, b) => (a?.position || 1) - (b?.position || 1))
            : [],
        }))
      );
  }
}
