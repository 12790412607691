import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Business, PaginationResult } from "@menufy/models";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { UserAuthService } from "../../auth/services/user-auth.service";
import { ListBusinessParams } from "../interfaces/find-business";
import { LOCALE_LANG_ID } from "@eqn/angular-i18n";

@Injectable({
  providedIn: "root",
})
export class BusinessesApiService {
  private readonly _businessApiBaseUrl = `${environment.apiBaseUrl}/businesses`;
  private readonly _businessApiTranslationBaseUrl = `${environment.apiBaseUrl}/i18n/businesses`;

  constructor(private readonly _http: HttpClient, private readonly _userAuth: UserAuthService) {}

  public addBusiness(body: FormData) {
    return this._http.post(this._businessApiBaseUrl, body, this._userAuth.getAuthorizationHeader());
  }

  public updateBusiness(formData: FormData, businessId: string) {
    const url = `${this._businessApiBaseUrl}/${businessId}`;
    return this._http.put(url, formData, this._userAuth.getAuthorizationHeader("", true));
  }

  public updateBusinessWithLocale(body: any, businessId: string) {
    const url = `${this._businessApiTranslationBaseUrl}/${businessId}`;
    return this._http.patch(url, body, {
      ...this._userAuth.getAuthorizationHeader("", true),
    });
  }

  public getBusinesses(params: ListBusinessParams): Observable<PaginationResult<Business>> {
    const urlParams = new URLSearchParams();

    const limit = `${params.limit}`;

    urlParams.append("limit", limit);

    if (params.before) {
      urlParams.append("before", params.before);
    } else if (params.after) {
      urlParams.append("after", params.after);
    }

    const url = `${this._businessApiBaseUrl}/search?${urlParams.toString()}`;

    return this._http.get<PaginationResult<Business>>(url, this._userAuth.getAuthorizationHeader());
  }

  public deleteBusiness(business: string): Observable<{ id: string }> {
    const url = `${this._businessApiBaseUrl}/${business}`;
    return this._http.delete<{ id: string }>(url, this._userAuth.getAuthorizationHeader());
  }

  public deleteBusinessImage(businessId: string, imageId: string): Observable<{ id: string }> {
    const url = `${this._businessApiBaseUrl}/${businessId}/images/${imageId}`;
    return this._http.delete<{ id: string }>(url, this._userAuth.getAuthorizationHeader());
  }

  public getBusinessDetail(businessId: string): Observable<Business> {
    const url = `${this._businessApiBaseUrl}/${businessId}`;

    return this._http.get<Business>(url, this._userAuth.getAuthorizationHeader("", true));
  }
}
