import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddBusinessApisAction, AddBusinessSuccessProps } from "../actions";
import { catchError, map, of, switchMap, tap } from "rxjs";
import { LoadingActions, LoadingState } from "../../../store";
import { Store } from "@ngrx/store";
import { BusinessesApiService } from "../../../my-business/services/businesses-api.service";
import { BusinessEditKeys } from "../../../my-business/interfaces/business-urls";
import { CustomTranslateService } from "@eqn/angular-i18n";

@Injectable()
export class AddBusinessEffects {
  addBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddBusinessApisAction.addBusiness),
      switchMap((payload) => {
        if (
          payload.editKey == BusinessEditKeys.Description &&
          payload.id &&
          payload?.translationId
        ) {
          const body = {
            description: {
              id: payload?.translationId,
              [this.translate.currentLang]: payload?.description || "",
            },
          };

          return this.businessesApi.updateBusinessWithLocale(body, payload?.id).pipe(
            tap(() => this.store.dispatch(LoadingActions.stopLoading())),
            map((response) =>
              AddBusinessApisAction.addBusinessSuccess(response as AddBusinessSuccessProps)
            ),
            catchError((error) => {
              this.store.dispatch(LoadingActions.stopLoading());
              return of(AddBusinessApisAction.addBusinessFailed({ error }));
            })
          );
        }

        const formData = new FormData();
        Array.from(payload.images || []).forEach((item) => formData.append(`images`, item as Blob));

        formData.append("name", payload?.name);

        if (
          payload?.description &&
          !payload?.description?.id &&
          payload.editKey == BusinessEditKeys.Description
        ) {
          formData.append("description", payload?.description as string);
        }

        if (payload?.id) {
          return this.businessesApi.updateBusiness(formData, payload?.id).pipe(
            tap(() => this.store.dispatch(LoadingActions.stopLoading())),
            map((response) =>
              AddBusinessApisAction.addBusinessSuccess(response as AddBusinessSuccessProps)
            ),
            catchError((error) => {
              this.store.dispatch(LoadingActions.stopLoading());
              return of(AddBusinessApisAction.addBusinessFailed({ error }));
            })
          );
        } else {
          return this.businessesApi.addBusiness(formData).pipe(
            tap(() => this.store.dispatch(LoadingActions.stopLoading())),
            map((response) =>
              AddBusinessApisAction.addBusinessSuccess(response as AddBusinessSuccessProps)
            ),
            catchError((error) => {
              this.store.dispatch(LoadingActions.stopLoading());
              return of(AddBusinessApisAction.addBusinessFailed({ error }));
            })
          );
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private businessesApi: BusinessesApiService,
    private store: Store<LoadingState>,
    private translate: CustomTranslateService
  ) {}
}
