import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, mergeMap, of } from "rxjs";
import { DishMenuActions } from "../actions";
import { AddMenuDishesState, AddMenuDishState } from "../dish.states";
import { DishesApiService } from "../../services/dish-api.service";
import { FileRecord, PreferenceLabelType } from "@menufy/models";
import { BusinessEditKeys } from "../../../my-business/interfaces/business-urls";

@Injectable()
export class DishMenuEffects {
  addBusinessMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishMenuActions.addMenuDishes),
      mergeMap((action: AddMenuDishesState) => {
        return from(action.dishes).pipe(
          mergeMap((dish) => {
            const hasValidI18nKey =
              dish?.editKey &&
              [
                BusinessEditKeys.Title,
                BusinessEditKeys.Description,
                BusinessEditKeys.AdditionalAllergensInfo,
                BusinessEditKeys.Ingredients,
              ]?.includes(dish?.editKey);

            if (dish?.editKey && hasValidI18nKey && dish?.id) {
              const payload = { ...dish };

              delete payload?.editKey;

              const enableI18n =
                dish.editKey == BusinessEditKeys.Title && dish?.name?.id
                  ? true
                  : dish?.editKey == BusinessEditKeys.Description && dish?.description?.id
                  ? true
                  : dish?.editKey == BusinessEditKeys.AdditionalAllergensInfo &&
                    dish?.additionalAllergensInfo?.id
                  ? true
                  : dish?.editKey == BusinessEditKeys?.Ingredients && dish?.ingredients?.id
                  ? true
                  : false;

              return this.dishService.updateMenuDish(payload, dish.id, enableI18n).pipe(
                map((res) => {
                  return DishMenuActions.addMenuDishesSuccess(res);
                }),
                catchError((error) => of(DishMenuActions.addMenuDishesFailed({ error })))
              );
            }

            const formData = new FormData();

            if (!dish?.name?.id) {
              formData.append("name", dish.name || "");
            }

            formData.append("menu", dish?.menu || "");
            formData.append("position", dish?.position ? dish?.position?.toString() : "1");

            if (dish.description && !dish?.description?.id) {
              formData.append("description", dish.description || "");
            }

            Array.from((dish.images as File[]) || []).forEach((image) => {
              const file = image as unknown as FileRecord;

              if (file instanceof Blob) {
                formData.append(`images`, image as Blob);
              }
            });

            Array.from(dish.prices || []).forEach((price, i) => {
              formData.append(`prices[${i}][amount]`, price?.amount?.toString() || "");
              if (price?.description) {
                formData.append(`prices[${i}][description]`, price?.description || "");
              }
            });

            Array.from(dish.allergens || []).forEach((allergen, i) => {
              formData.append(`allergens[${i}][name]`, allergen?.name || "");
              formData.append(
                `allergens[${i}][isOptional]`,
                allergen?.isOptional ? "true" : "false"
              );
              formData.append(`allergens[${i}][type]`, PreferenceLabelType.ALLERGEN || "");
            });

            Array.from(dish.diets || []).forEach((allergen, i) => {
              formData.append(`diets[${i}][name]`, allergen?.name || "");
              formData.append(`diets[${i}][isOptional]`, allergen?.isOptional ? "true" : "false");
              formData.append(`diets[${i}][type]`, PreferenceLabelType.DIET || "");
            });

            if (dish?.additionalAllergensInfo && !dish?.additionalAllergensInfo?.id) {
              formData.append("additionalAllergensInfo", dish?.additionalAllergensInfo || "");
            }

            if (dish?.ingredients && !dish?.ingredients?.id) {
              formData.append("ingredients", dish?.ingredients || "");
            }

            if (dish?.id) {
              return this.dishService.updateMenuDish(formData, dish?.id).pipe(
                map((res) => {
                  return DishMenuActions.addMenuDishesSuccess(res);
                }),
                catchError((error) => of(DishMenuActions.addMenuDishesFailed({ error })))
              );
            } else {
              return this.dishService.addMenuDish(formData).pipe(
                map((res) => {
                  return DishMenuActions.addMenuDishesSuccess(res);
                }),
                catchError((error) => of(DishMenuActions.addMenuDishesFailed({ error })))
              );
            }
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private dishService: DishesApiService) {}
}
