import { FileRecord, PreferenceLabel, Price } from "@menufy/models";
import { Nullable } from "@eqn/data-types";
import { BusinessEditKeys } from "../../my-business/interfaces/business-urls";

export interface AddMenuDishState {
  menu: string;
  name: string | any;
  description: string | any;
  position: number;
  images?: File[] | FileRecord[];
  prices?: Array<Nullable<Price>>;
  allergens?: Array<Nullable<PreferenceLabel>>;
  diets?: Array<Nullable<PreferenceLabel>>;
  additionalAllergensInfo?: string | any;
  ingredients?: string | any;
  id?: string;
  success?: boolean;
  error?: unknown;
  loading?: boolean;
  editKey?: BusinessEditKeys;
}

export interface AddMenuDishesState {
  dishes: AddMenuDishState[];
  loading: boolean;
  success: boolean;
  pendingRequests: number;
  error: unknown;
}

export interface AddPrice extends Price {
  dish: string;
}

export interface AddDishPricesState {
  dishes: AddMenuDishState[];
  prices: AddPrice[];
  loading: boolean;
  success: boolean;
  pendingRequests: number;
  error: unknown;
}

export const addMenuDishState: AddMenuDishState = {
  images: [],
  menu: "",
  name: "",
  description: "",
  position: 1,
  prices: [],
  allergens: [],
  diets: [],
  additionalAllergensInfo: "",
  ingredients: "",
  success: false,
  error: false,
  loading: false,
  id: "",
  editKey: undefined,
};

export const addMenuDishesState: AddMenuDishesState = {
  dishes: [],
  loading: false,
  pendingRequests: 0,
  success: false,
  error: false,
};

export const addDishPricesInitialState: AddDishPricesState = {
  dishes: [],
  prices: [],
  loading: false,
  pendingRequests: 0,
  success: false,
  error: false,
};
