import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LOCALE_LANG_ID } from "./locale.config";
import { BehaviorSubject, map, Observable } from "rxjs";
import { LocaleService } from "./locale.service";

@Injectable({
  providedIn: "root",
})
export class CustomTranslateService {
  private _currentLangId = new BehaviorSubject<LOCALE_LANG_ID | string>(
    this._localeService.currentLocale?.langId || "en"
  );

  private _currentLang!: LOCALE_LANG_ID | string;

  constructor(
    private readonly _translate: TranslateService,
    private readonly _localeService: LocaleService
  ) {}

  public getTranslations(key: string, langId: LOCALE_LANG_ID | string) {
    return this._translate.getTranslation(langId).pipe(
      map((res) => {
        return getNestedValue(res, key);
      })
    );
  }

  // get language
  public getCurrentLang$: Observable<LOCALE_LANG_ID | string> = this._currentLangId.asObservable();

  // set language
  public set currentLangId(langId: LOCALE_LANG_ID | string) {
    this.currentLang = langId;
    this._currentLangId.next(langId);
  }

  public get currentLang() {
    return this._currentLang || this._localeService.currentLocale.langId;
  }

  public set currentLang(langId: LOCALE_LANG_ID | string) {
    this._currentLang = langId;
  }

  public setDefaultTranslation() {
    this.currentLang = this._translate.currentLang;
    this.currentLangId = this._translate.currentLang;
  }
}

function getNestedValue(obj: any, path: string): any {
  return path
    ?.split(".")
    ?.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
}
