export enum BusinessReturnUrl {
  MenuDetails = "menuDetails",
  MenuList = "menuList",
  DishList = "dishList",
  DishDetails = "dishDetails",
}

export enum BusinessEditKeys {
  Title = "title",
  Description = "description",
  Price = "price",
  Preferences = "preferences",
  Images = "images",
  AdditionalAllergensInfo = "additionalAllergensInfo",
  Ingredients = "ingredients",
}
